import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import VerticalTextBlock from '../VerticalTextBlock';

import './index.scss';


const FaqSection = ({ children }) => (
  <section className="faq-section">
    <div className="faq-section__container container">
      <VerticalTextBlock
        sectionClassName="faq"
        text="F.A.Q."
        dotsStyle="hidden"
      />

      <div className="faq-section__content">

        <div className="faq-section__heading">
          <h2 className="faq-section__heading__text">
            Frequently
            <br />
            Asked
            <br />
            Questions
          </h2>
        </div>

        <div className="faq-section__accordion-wrap">

          {children}

          <Link
            to="/frequently-asked-questions.html"
            className="faq-section__btn btn btn_normal btn_without-bg btn_blue btn_blue-color"
          >
            Read full FAQ
          </Link>
        </div>

      </div>
    </div>
  </section>
);

FaqSection.propTypes = {
  children: PropTypes.node,
};

FaqSection.defaultProps = {
  children: undefined,
};

export default FaqSection;
