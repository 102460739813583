import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Layout from '../layouts/SimplePage';
import FirstScreenSection from '../components/FirstScreenSection';
import GradesSection from '../components/GradesSection';
import Calculator from '../components/Calculator';
import Testimonials from '../components/Testimonials';
import OurFeaturesSection from '../components/OurFeaturesSection';
import LastCompletedProjects from '../components/LastCompletedProjects';
import FaqSection from '../components/FaqSection';
import { Accordion, AccordionItem } from '../components/Accordion';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../components/SeoText';
import CTA from '../components/CTA';

const IndexPage = () => (
  <Layout pageWrapperClassName="home-page" headerClassName="header_transparent-bg">
    <Helmet
      title="Essay Writing Service - Take Away Essay Service"
      meta={[
        {
          name: 'description',
          content:
            'Take Away Essay is a reliable Essay Writing Service with a team of professional writers ready to help 24/7. Clients’ reviews guarantee top quality of the service, fast delivery and 100% satisfaction.',
        },

        {
          property: 'og:title',
          content:
            'Essay Writing Service You Were Looking for: Quality and Affordability | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content:
            'If your requirements for an essay writing service are strict, we are ready to be tested! Here you can order a paper of a school level, as well as undergraduate and Master’s academic levels. We offer guarantees, provide samples and free features. Check out what else we have up our sleeve!',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[{ rel: 'canonical', href: 'https://takeawayessay.com/' }]}
    />

    <FirstScreenSection btnClassName="awesome-btn_white" isLogo isVideo>
      <h2 className="first-screen-section__title">Easy way to better grades</h2>
      <p className="first-screen-section__heading">
        We write custom essay samples to help international
        <br className="first-screen-section__heading__br" /> students succeed with their studies
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />
    <LastCompletedProjects />

    <FaqSection>
      <Accordion>
        <AccordionItem title="Is it legal to use your custom writing service?">
          <p>
            Absolutely. We provide you with a custom sample paper, and this kind of assistance is
            not prohibited by any academic institution. Using our service is like hiring a tutor who
            can help you with your writing assignments. So, a paper you get from us is{' '}
            <Link to="/legit-writing-service.html">completely legit</Link>.
          </p>
        </AccordionItem>

        <AccordionItem title="Can you write an essay the same day I place an order?">
          <p>
            Yes, we can do it in <Link to="/urgent-writing-service.html">just 8 hours</Link>. We
            operate 24/7, so you can place your order at any time. If you prefer to talk to a person
            and think it is a faster way to tell us what you need, call us at{' '}
            <a href="tel:+18552605455">1-855-260-5455</a>.
          </p>
        </AccordionItem>

        <AccordionItem title="Who will write my paper?">
          <p>
            When we get an order from you, we carefully study all your requirements and choose a
            writer who has all necessary skills and experience to cope with it.
          </p>
          <p>
            All of our writers are subdivided into 4 writing hubs, each specializing in a certain
            field of study: business studies, STEM, social studies, and law. With our 531 writers,
            we can cover practically any subject you study.
          </p>
        </AccordionItem>

        <AccordionItem title="What if I’m not happy with the paper I got?">
          <p>
            It is highly unlikely we may fall below your expectations: we carefully check your
            instructions and ask additional questions to know what kind of help you need; our{' '}
            <Link to="/quality-assurance.html" rel="nofollow">
              Quality Assurance Department
            </Link>{' '}
            checks every paper to make sure all your requirements are met.
          </p>
          <p>
            However, if you are not satisfied with your paper, you can request{' '}
            <Link to="/revisions-policy.html" rel="nofollow">
              a free revision
            </Link>
            . As a last resort, you may request a refund and get your{' '}
            <Link to="/money-back-guarantee.html" rel="nofollow">
              money back
            </Link>
            . But as you can see, we’ve done everything to minimize the chance of a negative
            outcome.
          </p>
        </AccordionItem>

        <AccordionItem title="How do I know my paper is plagiarism-free?">
          <p>
            We check every paper using our advanced plagiarism-detection software. While the
            majority of plagiarism checkers are capable of detecting exact matches, we can also
            detect tricky manipulations like paraphrasing, substituting words with synonyms, and
            changing active voice to passive. This way, we know we provide you with{' '}
            <Link to="/plagiarism-free-guarantee.html" rel="nofollow">
              a plagiarism-free paper
            </Link>
            .
          </p>
        </AccordionItem>

        <AccordionItem title="Where is your company located?">
          <p>
            TakeAwayEssays.com is a website provided by
            <span className="hidden-info" data-title="Writera Limited" />, a company based in
            Cyprus. Our address is{' '}
            <span
              className="hidden-info"
              data-title="Vasili Michalidi, 9, 3026, Limassol, Cyprus"
            />
            . We also have a support team in Ukraine. Contact us for any additional information.
          </p>
        </AccordionItem>
      </Accordion>
    </FaqSection>

    <SeoTextSection>
      <SeoTextHeading>
        Take away essay service: essay writing service
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            Feel like you might need some professional assistance with your studies? Our essay
            writing service can help you by providing you with samples of difficult writing tasks.
            Choose the grade of the paper and leave everything else to our professional writers.
          </p>
          <p>
            <strong>
              TakeAwayEssay.com is a top trusted service that has everything you’re looking for! We
              provide high-quality help with rushessay papers for a humble price, improving
              students’ lives and giving them more free time.
            </strong>{' '}
            There’s a lot our trustworthy essay writing service has to offer, so let’s dive into it!
          </p>

          <h2 className="h3">
            Online essay writing service you can trust with a difficult assignment
          </h2>
          <p>
            Choosing an online essay writing service is difficult nowadays. There are so many
            options, and a lot of them have great terms. Before launching, we’ve researched the
            market and decided most services have at least one flaw. They’re still reliable, but one
            charges extra money for the stuff that has to be free, another one lacks speed, and so
            on.
          </p>
          <p>
            So, we decided to be the balancing buoy in the ocean of good essay writing services.
            Hiring better writers, training them to write fast, and establishing a quick customer
            service worked!
          </p>
          <p>
            As a result of our hard work, here’s what our professional essay writing service is able
            to provide you with:
          </p>
          <ul className="dot-list">
            <li>High-quality writing on a wide range of topics</li>
            <li>Deadlines as soon as 8 hours (or even 4 hours if you need no more than 2 pages)</li>
            <li>Friendly and effective 24/7 customer support</li>
            <li>International student help: the USA, Canada, etc.</li>
          </ul>
          <p>
            Using our service, rest assured we adjust to the American, English, etc. dialect
            guidelines. Our quality assurance experts go through every piece of writing and fix
            whatever mistakes may be left.
          </p>
          <p>Plus, our top-rated essay writing service is quite affordable:</p>
          <ul className="dot-list">
            <li>School level for $10 per page;</li>
            <li>College undergraduate level for $15 per page;</li>
            <li>University Master’s level for $25 per page.</li>
          </ul>
          <p>
            The rates grow with the urgency and size of the paper, but that won’t be a problem since
            we also offer free title and bibliography pages, revisions (if asked for within a week
            after delivery), formatting, grading criteria, anti-plagiarism checker, and samples!
          </p>
          <p>
            Here at this reliable essay writing service, we value transparency, so you can see the
            exact price for your assignment using our calculator. If you want to buy additional
            features, we have them for cheap:
          </p>
          <ul className="dot-list">
            <li>Samples from a certain writer</li>
            <li>Charts</li>
            <li>PowerPoint slides</li>
            <li>A list of links to used research sources</li>
            <li>Part-by-part payment</li>
          </ul>
          <p>
            The latter is available for large papers like a dissertation or a university-level
            essay. You get the finished parts one by one, paying for them separately. This helps you
            balance finances and prove that we’re reliable. The feature adds 10% of the paper price,
            but if the order is large, it’s highly recommended.
          </p>

          <h2 className="h3">
            Professional essay writing service: what we guarantee our customers
          </h2>
          <p>
            Top essay writing services must ensure the safety of their customers. We do a good job
            when it comes to your money, data, and assignment quality. Here’s what we guarantee:
          </p>
          <ul className="dot-list">
            <li>
              <strong>Money-back policy</strong>
              <br /> If there’s a difficulty with the order or you’re not satisfied, a refund is
              available. Our committee will review your case in 3-4 days or up to 14 days if the
              issue is connected to the quality of the paper.
            </li>
            <li>
              <strong>Full confidentiality</strong>
              <br /> We stick to the GDPR and apply our own privacy policy to make sure your
              personal data is protected.
            </li>
            <li>
              <strong>Anti-plagiarism</strong>
              <br /> As a reliable essay writing service, we check every text with specialized
              anti-plagiarism software.
            </li>
          </ul>

          <h2 className="h3">
            Top-rated essay writing service: ways we can help you improve your life
          </h2>
          <p>
            A lot of students miss out on the majority of their college life by constantly doing
            homework or writing papers. While studies are an essential part of our growth, sometimes
            there’s too much of it. That’s what our team of 531 trustworthy writers is helping with
            — improving your writing skills and hence making more time for life.
          </p>
          <p>With help from our professional essay writing service, you can:</p>
          <ul className="dot-list">
            <li>Get a part-time job</li>
            <li>Get a hobby</li>
            <li>Travel</li>
            <li>Meet with friends</li>
            <li>Have “me” time</li>
          </ul>
          <p>
            These are all very important for our development as well, and the best way to make time
            for both education and life in general is letting us help you write your essays.
            Everything you have to do is place your first “do my essay” online paid order! Thousands
            of students already did, trusting us to write more than a million pages!
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA>
      Need a better grade?
      <br /> We&#39;ve got you covered.
    </CTA>
  </Layout>
);

export default IndexPage;
